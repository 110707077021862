import React from "react";
import styled from "@emotion/styled";
import { container, fontSize, spacing, screen } from "../../../styles/mixins";
import { css, useTheme } from "@emotion/react";
import { abcMaxiPlusMono, richText } from "../../../styles/typography-act-2";
import { useShuffle } from "../../../util/useShuffle";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { positions } from "./BodyCopy";

const Container = styled.div`
	${({ theme: { alignment, columns } }) =>
		container({ cols: columns ? columns : alignment === "modal" ? 36 : 48 })};
	${({ theme: { alignment } }) =>
		spacing(
			"marginTop",
			alignment === "modal" || alignment === "largeModal" ? "xl" : "xxl"
		)};
	${({ theme: { alignment } }) =>
		spacing(
			"marginBottom",
			alignment === "modal" || alignment === "largeModal" ? "xl" : "xxl"
		)};
	position: relative;
	z-index: 3;
	align-content: center;
`;

const quoteSizesDefault = {
	large: css`
		${fontSize(4.2)};
		grid-column: 2 / -2;
		@media ${screen("md")} {
			grid-column: 4 / -4;
		}

		@media ${screen("ml")} {
			grid-column: 7 / -7;
		}
	`,
	left: css`
		${fontSize(4.2)};
		grid-column: 7 / 23;
	`,
	small: css`
		${fontSize(2.7)};
		${positions.center};
	`,
};

const quoteSizes = {
	left: {
		large: quoteSizesDefault.large,
		left: quoteSizesDefault.left,
		small: css`
			${fontSize(2.7)};
			${positions.left};
		`,
	},
	center: {
		large: quoteSizesDefault.large,
		left: quoteSizesDefault.left,
		small: quoteSizesDefault.small,
	},
	right: {
		large: quoteSizesDefault.large,
		left: quoteSizesDefault.left,
		small: css`
			${fontSize(2.7)};
			${positions.right};
		`,
	},
	modal: {
		large: quoteSizesDefault.large,
		left: quoteSizesDefault.left,
		small: css`
			${fontSize(2.7)};
			${positions.modal};
		`,
	},
	largeModal: {
		large: quoteSizesDefault.large,
		left: quoteSizesDefault.left,
		small: css`
			${fontSize(2.7)};
			${positions.largeModal};
		`,
	},
};

const topMetaLeftSizesDefault = {
	large: css`
		grid-column: 2 / -2;
		@media ${screen("md")} {
			grid-column: 4 / -4;
		}

		@media ${screen("ml")} {
			grid-column: 7 / -7;
		}
	`,
	left: css`
		grid-column: 7 / 12;
	`,
	small: css`
		${positions.center};
	`,
};
const topMetaLeftSizes = {
	left: {
		large: topMetaLeftSizesDefault.large,
		left: topMetaLeftSizesDefault.left,
		small: css`
			grid-column: 7 / 29;
		`,
	},
	center: {
		large: topMetaLeftSizesDefault.large,
		left: topMetaLeftSizesDefault.left,
		small: topMetaLeftSizesDefault.small,
	},
	right: {
		large: topMetaLeftSizesDefault.large,
		left: topMetaLeftSizesDefault.left,
		small: css`
			grid-column: 27 / -7;
		`,
	},
	modal: {
		large: topMetaLeftSizesDefault.large,
		left: topMetaLeftSizesDefault.left,
		small: css`
			grid-column: 21 / 26;
		`,
	},
	largeModal: {
		large: topMetaLeftSizesDefault.large,
		left: topMetaLeftSizesDefault.left,
		small: css`
			grid-column: 25 / 32;
		`,
	},
};
const topMetaQuotesSizesDefault = {
	large: css`
		grid-column: 17 / -7;
	`,
	left: css`
		grid-column: 17 / -7;
	`,
	small: css`
		grid-column: 32 / -14;
	`,
};
const topMetaQuotesSizes = {
	left: {
		large: topMetaQuotesSizesDefault.large,
		left: topMetaQuotesSizesDefault.left,
		small: css`
			grid-column: 25 / 29;
		`,
	},
	center: {
		large: topMetaQuotesSizesDefault.large,
		left: topMetaQuotesSizesDefault.left,
		small: topMetaQuotesSizesDefault.small,
	},
	right: {
		large: topMetaQuotesSizesDefault.large,
		left: topMetaQuotesSizesDefault.left,
		small: css`
			grid-column: 39 / -7;
		`,
	},
	modal: {
		large: topMetaQuotesSizesDefault.large,
		left: topMetaQuotesSizesDefault.left,
		small: css`
			grid-column: 33 / 36;
		`,
	},
	largeModal: {
		large: topMetaQuotesSizesDefault.large,
		left: topMetaQuotesSizesDefault.left,
		small: css`
			grid-column: 36 / 39;
		`,
	},
};
const bottomMetaSizesDefault = {
	large: css`
		grid-column: 2 / -2;
		@media ${screen("md")} {
			grid-column: 4 / -4;
		}

		@media ${screen("ml")} {
			grid-column: 7 / -7;
		}
	`,
	left: css`
		grid-column: 7 / -7;
	`,
	small: css`
		grid-column: 14 / -14;
	`,
};
const bottomMetaSizes = {
	left: {
		large: bottomMetaSizesDefault.large,
		left: bottomMetaSizesDefault.left,
		small: css`
			grid-column: 7 / 29;
		`,
	},
	center: {
		large: bottomMetaSizesDefault.large,
		left: bottomMetaSizesDefault.left,
		small: bottomMetaSizesDefault.small,
	},
	right: {
		large: bottomMetaSizesDefault.large,
		left: bottomMetaSizesDefault.left,
		small: css`
			grid-column: 21 / -7;
		`,
	},
	modal: {
		large: bottomMetaSizesDefault.large,
		left: bottomMetaSizesDefault.left,
		small: css`
			grid-column: 15 / 35;
		`,
	},
	largeModal: {
		large: bottomMetaSizesDefault.large,
		left: bottomMetaSizesDefault.left,
		small: css`
			grid-column: 25 / -3;
		`,
	},
};

const authorSizesDefault = {
	large: css`
		grid-column: 2 / -2;
		@media ${screen("md")} {
			grid-column: 4 / -4;
		}

		@media ${screen("ml")} {
			grid-column: 7 / -7;
		}
	`,
	left: css`
		grid-column: 7 / 23;
	`,
	small: css`
		${positions.center};
	`,
};

const authorSizes = {
	left: {
		large: authorSizesDefault.large,
		left: authorSizesDefault.left,
		small: css`
			grid-column: 7 / 29;
		`,
	},
	center: {
		large: authorSizesDefault.large,
		left: authorSizesDefault.left,
		small: authorSizesDefault.small,
	},
	right: {
		large: authorSizesDefault.large,
		left: authorSizesDefault.left,
		small: css`
			grid-column: 21 / -7;
		`,
	},
	modal: {
		large: authorSizesDefault.large,
		left: authorSizesDefault.left,
		small: css`
			grid-column: 15 / 35;
		`,
	},
	largeModal: {
		large: authorSizesDefault.large,
		left: authorSizesDefault.left,
		small: css`
			grid-column: 25 / -3;
		`,
	},
};

const linePositions = {
	1: css`
		grid-row: 1;
		align-self: center;
	`,
	2: css`
		grid-row: 2;
		align-self: start;
		${spacing("marginTop", 3.5)};
	`,
	3: css`
		grid-row: 7;
		align-self: end;
		${spacing("marginBottom", 3.5)};
	`,
	4: css`
		grid-row: 8;
		align-self: center;
	`,
	5: css`
		grid-row: 9;
		align-self: center;
	`,
};

const Quote = styled.blockquote`
	${abcMaxiPlusMono};
	font-feature-settings: "ss06" on, "ss05" on, "ss07" on, "ss08" on;
	text-align: justify;
	line-height: 1.1;
	font-weight: 400;
	grid-row: 2 / 8;
	${spacing("marginTop", 3.5)};
	${spacing("marginBottom", 3.5)};
	${({ size, theme: { alignment } }) => quoteSizes[alignment][size]};
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					background: ${theme.background}99;
					${spacing("padding", "s")};
					border-radius: 20px;
					border: 1px solid ${theme.foreground}28;
			  `
			: null;
	}};

	a {
		border-bottom: 1px solid ${({ theme }) => theme.foreground}AC;
		transition: border-color 400ms ease;

		&:hover {
			border-bottom: 1px solid ${({ theme }) => theme.foreground}FF;
		}
	}
`;
const meta = css`
	${abcMaxiPlusMono};
	font-weight: 400;
	${fontSize(-0.8)};
	letter-spacing: 0.02em;
	justify-self: start;
`;
const MetaTopLeft = styled.div`
	${meta};
	grid-row: 1;
	${({ size, theme: { alignment } }) => topMetaLeftSizes[alignment][size]};
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					padding: 10px 12px;
			  `
			: null;
	}}
	> div {
		${({ theme }) => {
			return theme.alignment !== "modal" && theme.alignment !== "largeModal"
				? css`
						background: ${theme.background}99;
						padding: 10px 12px;
						border-radius: 20px;
						border: 1px solid ${theme.foreground}28;
				  `
				: null;
		}};
	}
`;
const MetaQuote = styled.div`
	${meta};
	grid-row: 1;
	${({ size, theme: { alignment } }) => topMetaQuotesSizes[alignment][size]};
`;
const MetaBottom1 = styled.div`
	${meta};
	grid-row: 9;
	justify-self: start;
	align-self: start;
	${({ size, theme: { alignment } }) => bottomMetaSizes[alignment][size]};
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					background: ${theme.background}99;
					padding: 10px 12px;
					border-radius: 20px;
					border: 1px solid ${theme.foreground}28;
			  `
			: null;
	}};
`;
const MetaBottom2 = styled.div`
	${meta};
	grid-row: 9;
	${({ size, theme: { alignment } }) => bottomMetaSizes[alignment][size]};
`;

const Content = styled.div`
	${richText};
	grid-row: 6 / 11;
	grid-column: 27 / 43;
`;

const Author = styled.div`
	${meta};
	${({ size, theme: { alignment } }) => authorSizes[alignment][size]};
	grid-row: 8;
	text-align: left;
	justify-self: start;
	${spacing("marginBottom", 3.5)};
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					padding: 10px 12px;
			  `
			: null;
	}}
	> div {
		${({ theme }) => {
			return theme.alignment !== "modal" && theme.alignment !== "largeModal"
				? css`
						background: ${theme.background}99;
						padding: 10px 12px;
						border-radius: 20px;
						border: 1px solid ${theme.foreground}28;
				  `
				: null;
		}};
	}
`;

const BlockQuote = ({ quote, size, quoteIndex, content, attribution }) => {
	size = size || "large";
	const length = [...quote].length;

	let quoteCount = `${quoteIndex}`.padStart(3, "0");

	const [topMetaRef] = useShuffle({});
	const [metaBottom1Ref] = useShuffle({});
	const [authorRef] = useShuffle({});

	return (
		<Container size={size}>
			<MetaTopLeft size={size} ref={topMetaRef}>
				PULL QUOTE
			</MetaTopLeft>

			<Quote size={size} dangerouslySetInnerHTML={{ __html: quote }} />
			<MetaBottom1 ref={metaBottom1Ref} size={size}>
				<div>NO. {quoteCount}</div>
				<div>{length} CHARACTERS</div>
			</MetaBottom1>
			<Author
				size={size}
				dangerouslySetInnerHTML={{ __html: attribution }}
				ref={authorRef}
				style={{ display: attribution ? "block" : "none" }}
			/>

			{size === "left" && (
				<Content dangerouslySetInnerHTML={{ __html: content }} />
			)}
		</Container>
	);
};

export default BlockQuote;
